<template>
  <right-content>
    <edit-info :editInfoList=editInfoList @submitfn="submitForm"></edit-info>
   </right-content>
</template>

<script>
import { isPhoneNum } from "@/utils/validate";
import RightContent from '@/views/commonViews/RightContent'
import {editJuniorInformation} from '@/api';
import EditInfo from "@/views/BaseInfo/editInfo"
export default {
  data(){
    return {
      editInfoList:[
        {name:"项目部名称", field:"name"},
        {name:"项目部管理员", field:"people_name"},
        {name:"联系电话", field:"phone_number"},
        {disable:true}
      ]
    }
  },
  methods:{
    async submitForm(form){
      try{
        if(!isPhoneNum(form.phone_number)) {
          throw new Error("手机格式错误")
        }

        const requestData = {
          type:2,
          id:form.id,
          value:form
        }
        //删除属性id
        delete requestData.value.id;
        const res = await editJuniorInformation(requestData);
        if(res.status == 200) {
          this.$success("修改成功")
          this.$router.push({
            path:"/company/manager-company"
          })
        }
      }catch (e) {
        if(e instanceof Error){
          this.$error(e.message)
        }
      }
    }
  },
  components:{
    EditInfo,
    RightContent
  }
}
</script>
<style lang="scss">

</style>
